export default [
  { value: -1, text: 'Aucun' },
  { value: 1, text: 'Asiatique' },
  { value: 2, text: 'Japonais' },
  { value: 3, text: 'Thaïlandais' },
  { value: 4, text: 'Chinois' },
  { value: 5, text: 'Italien' },
  { value: 6, text: 'Fast-food' },
  { value: 7, text: 'Salon de thé' },
  { value: 8, text: 'Bubble tea' },
  { value: 9, text: 'Américain' },
  { value: 10, text: 'Français' },
  { value: 11, text: 'Sandwich' },
  { value: 12, text: 'Burger' },
  { value: 13, text: 'Vietnamien' },
  { value: 14, text: 'Poulet' },
  { value: 15, text: 'Glace' },
  { value: 16, text: 'Coréen' },
  { value: 17, text: 'Indien' },
  { value: 18, text: 'À volonté' },
  { value: 19, text: 'Halal' },
  { value: 20, text: 'Mexicain' },
  { value: 21, text: 'Pizza' },
  { value: 22, text: 'Sushi' },
  { value: 23, text: 'Brunch' },
  { value: 24, text: 'Healthy' },
  { value: 25, text: 'Salade' },
  { value: 26, text: 'Marocain' },
  { value: 27, text: 'Fruits de mer' },
  { value: 28, text: 'Pâtes' },
  { value: 29, text: 'Maison de Bao' },
  { value: 30, text: 'Cuisine chinoise' },
  { value: 31, text: 'Cuisine du monde' },
  { value: 32, text: 'Buffet' },
  { value: 33, text: 'Buffet à volonté' },
  { value: 34, text: 'Pakistanais' },
  { value: 35, text: 'Orientale' },
  { value: 36, text: 'Vegan' },
  { value: 37, text: 'Halal' },
  { value: 38, text: 'Spécialité hawaïenne' },
  { value: 39, text: 'Ramen' },
  { value: 40, text: 'Cambodgien' },
  { value: 41, text: 'Laotien' },
];